import React, { useState, useEffect } from 'react';
//import { Typography, Button } from '@mui/material';
import { ChevronRight, GraduationCap, Users, Calendar, Heart, BookOpen, Award, Smile } from 'lucide-react';
import { Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';


const slideData = [
  {
    image: "/images/graduaion_3.JPG",
    article: "Celebrating the school's graduation ceremonies.",
  },
  {
    image: "/images/carousel-1.jpg",
    article: "Discover inclusive education opportunities at GS Institut Filippo Smaldone.",
  },
  {
    image: "/images/carousel-2.jpg",
    article: "Our facilities provide equal support for both deaf and hearing students.",
  },
  {
    image: "/images/carousel-3.jpg",
    article: "Join a dynamic community of enthusiastic learners and dedicated educators.",
  },
  {
    image: "/images/IMG_7434.jpg",
    article: "Get hands-on experience through practical field training.",
  },
  {
    image: "/images/IMG_20230426_143720_085.jpg",
    article: "Enhance your digital skills with our ICT lessons.",
  },
  {
    image: "/images/IMG-20230214-WA0003.jpg",
    article: "Immerse yourself in our vibrant and diverse cultural setting.",
  },
  {
    image: "/images/huye_museum.jpg",
    article: "Engage in enriching museum visits and cultural experiences.",
  },
  {
    image: "/images/Graduation_day.JPG",
    article: "Memorable moments from the school's graduation day.",
  },
  {
    image: "/images/visiting_dam.jpg",
    article: "Enhancing science learning through hands-on field trips.",
  },
];




const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slideData.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative h-64 sm:h-96 md:h-[500px] overflow-hidden shadow-xl">
      <Helmet>
        <title>GS IFS - Leading Deaf Education in Rwanda</title>
        <meta name="description" content="GS Institut Filippo Smaldone - Premier inclusive education institution in Rwanda offering specialized programs for deaf and hearing students." />
        <meta name="keywords" content="Rwanda deaf school, inclusive education, special needs education, Catholic school Rwanda, best school Kigali" />
      </Helmet>

      {slideData.map((slide, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            index === currentSlide ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            backgroundImage: `linear-gradient(rgba(30, 58, 138, 0.3), rgba(30, 58, 138, 0.3)), url(${slide.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-blue-900 via-blue-900/80 to-transparent p-6 sm:p-8 md:p-12">
            <Typography 
              variant={isMobile ? "h6" : "h4"} 
              className="text-white font-bold max-w-4xl mx-auto text-center leading-tight"
              style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.3)' }}
            >
              {slide.article}
            </Typography>
          </div>
        </div>
      ))}

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slideData.map((_, idx) => (
          <button
            key={idx}
            className={`w-3 h-3 rounded-full transition-all ${
              currentSlide === idx ? 'bg-yellow-400 scale-125' : 'bg-white/50'
            }`}
            onClick={() => setCurrentSlide(idx)}
          />
        ))}
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div 
      className={`p-6 rounded-xl transition-all duration-300 ${
        hovered ? 'bg-white shadow-xl transform -translate-y-2' : 'bg-blue-50 shadow-lg'
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={`mb-4 p-3 rounded-full w-fit ${hovered ? 'bg-blue-100' : 'bg-white'}`}>
        {React.cloneElement(icon, { 
          size: 40, 
          className: `transition-colors ${hovered ? 'text-blue-600' : 'text-blue-400'}` 
        })}
      </div>
      <Typography variant="h6" className="text-lg font-bold mb-2 text-blue-900">
        {title}
      </Typography>
      <Typography variant="body2" className="text-gray-600">
        {description}
      </Typography>
    </div>
  );
};

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <Navbar />

      <main className="flex-grow">
        <Slideshow />

        {/* Welcome Section */}
        <section className="py-12 md:py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center">
              <div className="mb-8">
                <Typography 
                  variant="h2" 
                  className="text-3xl md:text-4xl lg:text-5xl font-bold text-blue-900 mb-6"
                >
                  Welcome to GS Institut Filippo Smaldone
                </Typography>
                <Typography 
                  variant="h5" 
                  className="text-lg md:text-xl text-gray-600 mb-8 leading-relaxed"
                >
                  A beacon of inclusive education in Rwanda, providing exceptional learning 
                  opportunities for both deaf and hearing students since 90s.
                </Typography>
                <div className="flex justify-center space-x-4">
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<ChevronRight />}
                    sx={{
                      bgcolor: '#1e3a8a',
                      '&:hover': { bgcolor: '#1e40af' },
                      px: 4,
                      py: 1.5,
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontSize: '1.1rem'
                    }}
                  >
                    Explore Programs
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      borderColor: '#1e3a8a',
                      color: '#1e3a8a',
                      '&:hover': { borderColor: '#1e40af' },
                      px: 4,
                      py: 1.5,
                      borderRadius: '8px',
                      textTransform: 'none',
                      fontSize: '1.1rem'
                    }}
                  >
                    Virtual Tour
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-12">
                {[
                  { number: '28+', label: 'Years Experience' },
                  { number: '1,200+', label: 'Students Enrolled' },
                  { number: '98%', label: 'Graduation Rate' },
                  { number: '50+', label: 'Qualified Staff' }
                ].map((stat, index) => (
                  <div key={index} className="p-4 bg-blue-50 rounded-lg">
                    <Typography variant="h4" className="text-blue-900 font-bold mb-1">
                      {stat.number}
                    </Typography>
                    <Typography variant="body2" className="text-gray-600">
                      {stat.label}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-16 bg-blue-900/5">
          <div className="container mx-auto px-4">
            <Typography 
              variant="h3" 
              className="text-3xl md:text-4xl font-bold text-center mb-12 text-blue-900"
            >
              Why Choose Our School?
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureCard
                icon={<GraduationCap />}
                title="Inclusive Education"
                description="Fully integrated programs for deaf and hearing students with specialized support services."
              />
              <FeatureCard
                icon={<BookOpen />}
                title="Comprehensive Curriculum"
                description="Academic excellence combined with vocational training and life skills development."
              />
              <FeatureCard
                icon={<Award />}
                title="Proven Success"
                description="Consistent top performance in national exams and vocational competitions."
              />
              <FeatureCard
                icon={<Smile />}
                title="Happy Environment"
                description="Safe, nurturing campus promoting social-emotional growth and cultural values."
              />
              <FeatureCard
                icon={<Users />}
                title="Expert Faculty"
                description="Highly qualified teachers with specialized training in inclusive education."
              />
              <FeatureCard
                icon={<Heart />}
                title="Catholic Values"
                description="Faith-based education emphasizing compassion, integrity, and community service."
              />
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-gradient-to-br from-blue-900 to-blue-800">
          <div className="container mx-auto px-4 text-center">
            <Typography 
              variant="h3" 
              className="text-3xl md:text-4xl font-bold text-white mb-6"
            >
              Ready to Start Your Journey?
            </Typography>
            <Typography 
              variant="body1" 
              className="text-lg text-blue-100 mb-8 max-w-2xl mx-auto leading-relaxed"
            >
              Join our vibrant community in Nyamirambo, Kigali. Applications open for 
              both deaf and hearing students from preschool through secondary education.
            </Typography>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="contained"
                size="large"
                sx={{
                  bgcolor: '#eab308',
                  '&:hover': { bgcolor: '#ca8a04' },
                  px: 5,
                  py: 1.5,
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}
              >
                Apply Now
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderColor: 'white',
                  color: 'white',
                  '&:hover': { borderColor: '#eab308' },
                  px: 5,
                  py: 1.5,
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1.1rem'
                }}
              >
                Schedule Visit
              </Button>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Home;